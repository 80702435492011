import React from 'react'
const dayjs = require('dayjs')
const weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

export const LunchHeader = ({
  currentOffer,
  lunchWeekStart,
  lunchWeekEnd,
  nextWeekStart,
  nextWeekEnd,
  validFrom,
  validTo,
  title,
  headline,
  overviewSubline,
  isBetweenOfferWeeks
}) => {
  const from = validFrom.replace('$date', lunchWeekStart)

  const to = validTo.replace('$date', lunchWeekEnd)

  const nextWeekFrom = validFrom.replace('$date', nextWeekStart)
  const nextWeekTo = validTo.replace('$date', nextWeekEnd)
  const currentWeekSubline = `${overviewSubline} ${from} bis ${to}`
  const nextWeekSubline = `${overviewSubline} ${nextWeekFrom} bis ${nextWeekTo}`

  return (
    <>
      <h1 className="mb-2">{title}</h1>
      <div className="flex flex-col text-base md:text-lg mb-12">
        <span className="px-12">{headline}</span>
        {currentOffer && !isBetweenOfferWeeks ? (
          <span>{currentWeekSubline}</span>
        ) : (
          <span>{nextWeekSubline}</span>
        )}
      </div>
    </>
  )
}
