import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import classNames from 'classnames'
import { BioButton } from '../shared'

export const LunchOptionalTeaser = ({
  rightItem,
  contentItem,
  rightButton,
  headlineTextShadow,
  subTitleUp
}) => {
  const subTitleElem = (
    <p className="normal-case text-sm lg:text-base headline-text-shadow-bold">
      {contentItem?.subline || contentItem?.teaserContent?.subtitle}
    </p>
  )
  const button = {
    customPath: `/mittagsgerichte-newsletter`,
    buttonCta: rightButton.buttonCta
  }

  return (
    <div className="block w-full lg:w-1/2 flex-auto h-80 lg:h-100 relative lg:flex-basis-1/2">
      <div className="bg-lunch-spoon bg-contain bg-no-repeat z-10 w-50 h-50 lg:w-80 lg:h-80 absolute top-55- left-90 sm:left-280 lg:top-70- lg:left-200" />
      <BgImage
        Tag="div"
        image={getImage(contentItem?.customImage?.image?.asset)}
        alt={contentItem?.customImage?.image?.alt}
        className="bm-rounded-box flex justify-center items-center w-full h-full px-8">
        <div className="text-center uppercase mt-10 text-white">
          {subTitleUp && subTitleElem}
          <h2
            className={classNames(
              'text-sm lg:text-xl',
              headlineTextShadow && 'headline-text-shadow-bold'
            )}>
            {rightItem &&
              (contentItem?.title || contentItem?.teaserContent?.title)}
          </h2>
          {!subTitleUp && subTitleElem}
          {rightItem && (
            <div className="pt-12">
              <BioButton button={button} />
            </div>
          )}
        </div>
      </BgImage>
    </div>
  )
}
