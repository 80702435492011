/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { LayoutBackground } from '../../components/shared'
import { BioLunchGrid } from '../shared'
import { LunchOfferNotAvailable } from './LunchOfferNotAvailable'
import { LunchHeader } from './LunchHeader'
import { LunchTeaser } from './LunchTeaser'
import { LunchOptionalTeaser } from './LunchOptionalTeaser'
import {
  lunchWeekStart,
  lunchWeekEnd,
  nextWeekStart,
  nextWeekEnd
} from './lunchDates'

import { sortByDate } from '../../utils'
import de from 'dayjs/locale/de'

const dayjs = require('dayjs')
dayjs.locale({
  ...de,
  weekStart: 1
})
let isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)
const weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)
let isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
let weekday = require('dayjs/plugin/weekday')
dayjs.extend(weekday)

export const Lunch = ({ data, market, pageContext }) => {
  const {
    title,
    postTeaserLeft,
    postTeaserRight,
    postTeaserPreview,
    overviewHeadline,
    overviewSubline,
    validFrom,
    validTo,
    noLunchOffer,
    noLunchOfferLinkTo,
    noLunchOfferLink,
    mealGreet
  } = data.sanityLunchPage.nodes[0]

  let currentWeek = data.allSanityLunchOffer?.nodes[0]
  let currentOffer = currentWeek?.menuItems && sortByDate(currentWeek.menuItems)
  let nextWeek = data.nextLunchOffer?.nodes[0]
  let nextOffer = nextWeek?.menuItems && sortByDate(nextWeek.menuItems)
  const marketHeadline = market?.address?.additionalAddress
    ? `${market?.name} ${market?.address?.additionalAddress}`
    : market?.name
  const headline = overviewHeadline.replace('$name', marketHeadline)

  if (!currentWeek && !nextWeek)
    return (
      <LunchOfferNotAvailable
        title={pageContext?.title || title}
        headline={headline}
        mealGreet={mealGreet}
        noLunchOffer={noLunchOffer}
        noLunchOfferLink={noLunchOfferLink}
        noLunchOfferLinkTo={noLunchOfferLinkTo}
      />
    )

  const lastCurrentMenuDate = currentWeek
    ? currentOffer[currentOffer.length - 1].day
    : null
  const nextFriday = dayjs().weekday(4)
  const firstNextMenuDate = nextWeek ? nextOffer[0].day : null
  let flagIsBetweenOfferWeeks = false // Sa - So
  let flagDisplayNextWeekOffer = false // Fr - So
  let flagDisplayExtraTeaser = false // Fr
  let flagDisplayValidWholeWeek = false // Mo - Fr

  if (dayjs().startOf('week').isSame(firstNextMenuDate, 'day')) {
    currentWeek = Object.assign({}, nextWeek)
  }

  if (dayjs().isBetween(nextFriday, dayjs(firstNextMenuDate), 'day')) {
    flagIsBetweenOfferWeeks = true
  }

  //Vorschau der nächsten Woche
  if (dayjs().isBetween(nextFriday, dayjs(firstNextMenuDate), 'day', '[)')) {
    flagDisplayNextWeekOffer = true
  }

  if (
    currentOffer &&
    dayjs().isBetween(nextFriday, dayjs(lastCurrentMenuDate), 'day', '[]')
  ) {
    flagDisplayExtraTeaser = true
  }

  currentWeek && dayjs().isSameOrBefore(lastCurrentMenuDate, 'day')
    ? (flagDisplayValidWholeWeek = true)
    : null

  const [isBetweenOfferWeeks, setIsNextWeekOffer] = useState(
    flagIsBetweenOfferWeeks
  )
  const [displayNextWeekOffer, setDisplayNextWeekOffer] = useState(
    flagDisplayNextWeekOffer
  )
  const [displayExtraTeaser, setdisplayExtraTeaser] = useState(
    flagDisplayExtraTeaser
  )
  const [displayValidWholeWeek, setDisplayValidWholeWeek] = useState(
    flagDisplayValidWholeWeek
  )
  const nextWeekOfferDate = `${nextWeekStart(nextWeek)} - ${nextWeekEnd(
    nextWeek
  )}`
  const menuePreview = `${postTeaserPreview?.subline} ${nextWeekOfferDate}`
  const comment = currentWeek?.comment || nextWeek?.comment
  const organicControlText =
    currentWeek?.organicControlText || nextWeek?.organicControlText
  const legalDisclaimer =
    currentWeek?.priceLegalText || nextWeek?.priceLegalText

  return (
    <LayoutBackground
      lunch
      headerComponent={
        <LunchHeader
          currentOffer={currentOffer}
          lunchWeekStart={lunchWeekStart(currentWeek)}
          lunchWeekEnd={lunchWeekEnd(currentWeek)}
          nextWeekStart={nextWeekStart(nextWeek)}
          nextWeekEnd={nextWeekEnd(nextWeek)}
          validFrom={validFrom}
          validTo={validTo}
          title={title}
          headline={headline}
          isBetweenOfferWeeks={isBetweenOfferWeeks}
          overviewSubline={overviewSubline}
        />
      }>
      <div className="w-full pt-12 mb-24">
        <section className="flex flex-col lg:flex-row justify-between w-full">
          <LunchTeaser
            contentItem={postTeaserLeft}
            leftButton={postTeaserLeft.button}
            whiteFont
            headlineTextShadow
            leftItem
            subTitle
            currentWeek={currentWeek}
            weekStart={lunchWeekStart(currentWeek)}
            lunchWeekEnd={lunchWeekEnd(currentWeek)}
            nextWeekOfferDate={nextWeekOfferDate}
            nextWeek={nextWeek}
            postTeaserPreview={postTeaserPreview}
            isBetweenOfferWeeks={isBetweenOfferWeeks}
          />
          <LunchOptionalTeaser
            contentItem={postTeaserRight}
            rightButton={postTeaserRight.button}
            rightItem
            whiteFont
            headlineTextShadow
            subTitle
          />
        </section>
      </div>
      <div className="md:w-singlePageContentSection mx-auto">
        <BioLunchGrid
          currentWeek={currentWeek}
          nextWeekOfferDate={nextWeekOfferDate}
          nextWeek={nextWeek}
          legalDisclaimer={legalDisclaimer}
          postTeaserPreview={postTeaserPreview}
          menuePreview={menuePreview}
          displayNextWeekOffer={displayNextWeekOffer}
          displayExtraTeaser={displayExtraTeaser}
          displayValidWholeWeek={displayValidWholeWeek}
          organicControlText={organicControlText}
          comment={comment}
          lunchWeekStart={lunchWeekStart(currentWeek)}
          lunchWeekEnd={lunchWeekEnd(currentWeek)}
          nextWeekStart={nextWeekStart(nextWeek)}
          nextWeekEnd={nextWeekEnd(nextWeek)}
        />
      </div>
    </LayoutBackground>
  )
}
