import React from 'react'
import { LayoutBackground } from '../../components/shared'
import { Link } from 'gatsby'
import { getLocale, getLocaleUrl } from '../../utils/localeUtils'

export const LunchOfferNotAvailable = ({
  title,
  headline,
  mealGreet,
  noLunchOffer,
  noLunchOfferLink,
  noLunchOfferLinkTo
}) => {
  const noMenuHeader = (
    <>
      <h1>{title}</h1>
      <span className="flex flex-col text-lg mb-32 px-12">{headline}</span>
    </>
  )
  const replaceLink = noLunchOffer.replace('$linkTo', '')
  const replaceAll = replaceLink.replace('$greeting', '')
  const locale = getLocale()

  const offerLink = (
    <Link
      to={getLocaleUrl(locale, noLunchOfferLink)}
      className="hover:text-green cursor-pointer font-bold">
      {noLunchOfferLinkTo}
    </Link>
  )

  const offerSlug = (
    <div className="w-5/6 mx-auto text-xl font-semibold mb-12">
      {replaceAll} {offerLink}.<div>{mealGreet}</div>
    </div>
  )
  return (
    <LayoutBackground headerComponent={noMenuHeader}>
      {offerSlug}
    </LayoutBackground>
  )
}
